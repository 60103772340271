<template>
  <div v-if="items" class="top-view-table">
    <h2 class="mt-5">{{ title }}</h2>
    <tirscript3-table
      :items="items"
      :pages="pages"
      template-columns="145px auto auto 300px auto auto"
      style="overflow-x: auto"
    >
      <template #header>
        <tirscript3-table-header-item
          >Дата/LogLevel</tirscript3-table-header-item
        >
        <tirscript3-table-header-item>Класс/Метод</tirscript3-table-header-item>
        <tirscript3-table-header-item>
          Размеры и время <br />
          Request/Response/Time
        </tirscript3-table-header-item>
        <tirscript3-table-header-item>
          SessionToken RequestId ConnectionId
        </tirscript3-table-header-item>
        <tirscript3-table-header-item>UserId Ip</tirscript3-table-header-item>
        <tirscript3-table-header-item>Действия</tirscript3-table-header-item>
      </template>
      <template #body="data">
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          {{ $filters.DateFormat(data.item.DateCreate) }} <br />
          {{ $filters.LogLevel(data.item.LevelId) }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          {{ data.item.Class }} / {{ data.item.Method }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          {{ data.item.RequestLength }} байт /
          {{ data.item.ResponseLength }} байт / {{ data.item.ExecTimeMs }} ms
          <br />
          Avg: {{ data.item.AverageRequestsSize }} байт /
          {{ data.item.AverageResponsesSize }} байт /
          {{ data.item.AverageExecutingTime }} ms
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="session-token table-item" :class="[type(data.item)]">
          {{ data.item.SessionToken }} <br />
          {{ data.item.RequestId }} <br />
          {{ data.item.ConnectionId }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          {{ data.item.UserId }} <br />
          {{ data.item.Ip }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          <router-link
            :to="{
              name: 'logs-view-detail',
              params: { id: data.item.RequestId },
            }"
            class="read-more"
          >
            Просмотр
          </router-link>
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>

    <div v-if="pages && items.length > 0" class="panel">
      <div class="row align-items-center">
        <div class="col-auto ml-auto">
          <tirscript3-pagination
            ref="pagination"
            :size="take"
            :count="pages.Count"
            class="mt-4"
            @onChange="onPaginationChanged"
          >
          </tirscript3-pagination>
        </div>
      </div>
    </div>

    <div class="row" v-show="items && isLoading == false">
      <div class="pb-2 pt-4 col-12" style="text-align: center">Нет данных</div>
    </div>
  </div>
</template>
<script lang="ts">
import moment from "moment";
import { Options, Vue, Prop } from "vue-property-decorator";
import TopEndpointLogItemExtends from "../models/TopEndpointLogItemExtends";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import EndpointLogsItemResponse
  from '@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse'
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "TopViewtable",
})
export default class TopViewTable extends Vue {
  // здесь инициализируются prop
  @Prop({ default: [] }) items: TopEndpointLogItemExtends[] = [];
  @Prop({ default: null }) pages: PageResponse = null;
  @Prop({ default: "" }) title: string;
  take = 10;

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  onPaginationChanged(page: number) {
    this.$emit("changePage", page);
  }
  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
  onReadmore(id: number) {
    this.$emit("editEndpointId", id);
    this.items.find((item) => item.RequestId == id.toString());
    this.$router.push({ name: "request-datail", params: { id: id } });
  }
  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }
}
</script>
<style lang="less">
.top-view-table {
  .read-more {
    cursor: pointer;
    color: blueviolet;
  }
}

.top-view-table .tirscript3-table .tirscript3-table-body-item,
.top-view-table .tirscript3-table .tirscript3-table-header-item {
  text-align: center;
  justify-content: center;
}

.session-token {
  font-family: monospace;
  font-size: 13px !important;
}
</style>
<style scoped>
.table-item.error {
  background: #FED6BC;
}
.table-item.warn {
  background: #FDEED9;
}
</style>