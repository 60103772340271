
import moment from "moment";
import { Options, Vue, Prop } from "vue-property-decorator";
import TopEndpointLogItemExtends from "../models/TopEndpointLogItemExtends";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import EndpointLogsItemResponse
  from '@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse'
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "TopViewtable",
})
export default class TopViewTable extends Vue {
  // здесь инициализируются prop
  @Prop({ default: [] }) items: TopEndpointLogItemExtends[] = [];
  @Prop({ default: null }) pages: PageResponse = null;
  @Prop({ default: "" }) title: string;
  take = 10;

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  onPaginationChanged(page: number) {
    this.$emit("changePage", page);
  }
  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
  onReadmore(id: number) {
    this.$emit("editEndpointId", id);
    this.items.find((item) => item.RequestId == id.toString());
    this.$router.push({ name: "request-datail", params: { id: id } });
  }
  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }
}
