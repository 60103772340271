
import { Options, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import TopHeightView from "@/views/page/methods-of-logs/components/top-height-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";

@Options<TopHeightMethodsLoadPage>({
  name: "TopHeightMethodsLoad",
  components: {
    Page,
    ViewFilter,
    TopHeightView,
  },
})
export default class TopHeightMethodsLoadPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  filterData() {
    (this.$refs.page as TopHeightView).onFilterChange();
  }
}
