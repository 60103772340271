<template>
  <page
    name="Топ методов с максимальным количеством вызовов"
    class="top-heigh-methods-load"
    :selectSource="false"
  >
    <template v-slot:toolbar>
      <filter-component
        v-model:filter="filterModel"
        @filterData="filterData"
        :selectInterval="false"
        :methods="false"
        :logsType="false"
        :logsLevel="false"
        :url="false"
        :requestId="false"
        :loggerName="false"
        :shortMessage="false"
      ></filter-component>
    </template>
    <top-height-view :filter="filterModel" ref="page"></top-height-view>
  </page>
</template>

<script lang="ts">
import { Options, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import TopHeightView from "@/views/page/methods-of-logs/components/top-height-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";

@Options<TopHeightMethodsLoadPage>({
  name: "TopHeightMethodsLoad",
  components: {
    Page,
    ViewFilter,
    TopHeightView,
  },
})
export default class TopHeightMethodsLoadPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  filterData() {
    (this.$refs.page as TopHeightView).onFilterChange();
  }
}
</script>

<style lang="less">
.top-heigh-methods-load {
}
// .set-filter {
//   display: flex;
//   justify-content: center;
//   color: orange;
//   font-weight: bold;
//   font-size: 18px;
// }
</style>