<template>
  <div v-if="filter" class="logs-view">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <!--Топ долгих запросов (Максимальные по времени) -->
    <div>
      <top-view-table
          :items="itemsTopReqTimeExtends"
          :pages="topReqTimeExtendsPage"
          title="Топ долгих запросов (Максимальные по времени)"
          @changePage="onChangePageTopReqTime"
      ></top-view-table>
    </div>
    <!--Сообщение об ошибке-->
    <div
        v-if="!error1.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error1.errorMessage }}
    </div>
    <!--Топ больших запросов (Максимальные по объему) -->
    <div>
      <top-view-table
          :items="itemsTopReqVolumeExtends"
          :pages="itemsTopReqVolumeExtendsPage"
          title="Топ больших запросов (Максимальные по объему)"
          @changePage="onChangePageTopReqValue"
      ></top-view-table>
    </div>
    <!--Сообщение об ошибке-->
    <div
        v-if="!error2.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error2.errorMessage }}
    </div>
    <!--Топ больших ответов (Максимальные по объему) -->
    <div>
      <top-view-table
          :items="itemsTopRespExtends"
          :pages="itemsTopRespExtendsPage"
          title="Топ больших ответов (Максимальные по объему)"
          @changePage="onChangePageTopResp"
      ></top-view-table>
    </div>
    <!--Сообщение об ошибке-->
    <div
        v-if="!error3.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error3.errorMessage }}
    </div>
    <!--Статистика для методов -->
    <div>
      <h2>Статистика для методов</h2>
      <tirscript3-table
          :items="itemsStat"
          :pages="topReqTimeExtendsPage"
          style="overflow-x: auto"
          template-columns="auto auto auto auto auto auto auto auto"
      >
        <template #header>
          <tirscript3-table-header-item>Класс/Метод</tirscript3-table-header-item>
          <tirscript3-table-header-item
          >Количество вызовов
          </tirscript3-table-header-item
          >
          <tirscript3-table-header-item
          >Среднее время выполнения
          </tirscript3-table-header-item
          >

          <tirscript3-table-header-item>
            Общее время выполнения ms
          </tirscript3-table-header-item>
          <tirscript3-table-header-item>
            Средний объем запросов в байтах
          </tirscript3-table-header-item>
          <tirscript3-table-header-item
          >Общий объем запросов в байтах
          </tirscript3-table-header-item
          >
          <tirscript3-table-header-item
          >Средний объем ответов в байтах
          </tirscript3-table-header-item
          >
          <tirscript3-table-header-item
          >Общий объем ответов в байтах
          </tirscript3-table-header-item
          >
        </template>
        <template #body="data">
          <tirscript3-table-body-item>
            {{ data.item.Class + ' / ' + data.item.Method }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.InvocationsCount }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.AverageExecutingTime }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.SummaryExecutingTime }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.AverageRequestsSize }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.SummaryRequestsLength }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.AverageResponsesSize }}
          </tirscript3-table-body-item>
          <tirscript3-table-body-item>
            {{ data.item.SummaryResponsesLength }}
          </tirscript3-table-body-item>
        </template>
      </tirscript3-table>

      <div v-show="itemsStat && itemsStat.length > 0" class="panel">
        <div class="row align-items-center">
          <div class="col-auto ml-auto">
            <tirscript3-pagination
                ref="pagination"
                :count="count"
                :size="size"
                class="mt-4"
                @onChange="onPaginationChanged"
            >
            </tirscript3-pagination>
          </div>
        </div>
      </div>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error4.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error4.errorMessage }}
      </div>
<!--      <div-->
<!--          v-if="itemsStat && itemsStat.length == 0 && isLoading == false"-->
<!--          class="row"-->
<!--      >-->
<!--        <div class="pb-2 pt-4 col-12" style="text-align: center">-->
<!--          Нет данных-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import TopViewTable from "@views/page/methods-of-logs/components/top-view-table.vue";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import EndpointLogsItemResponse
  from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse";
import GetTopEndpointLogsRequest
  from "@/dataSource/api/LoggerMicroService/Services/Models/Request/TopEndpointLogsService/GetTopEndpointLogsRequest";
import GetTopEndpointLogsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/TopEndpointLogsService/GetTopEndpointLogsResponse";
import GetEndpointStatisticsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/TopEndpointLogsService/GetEndpointStatisticsResponse";
import EndpointStatisticItem
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/TopEndpointLogsService/EndpointStatisticItem";
import TopEndpointLogItem
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/TopEndpointLogsService/TopEndpointLogItem";
import TopEndpointLogItemExtends from "../models/TopEndpointLogItemExtends";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import getUTC from "@/function/getUTC"

@Options({
  name: "TopHeightView",
  components: {
    TableView,
    TopViewTable,
  },
})
export default class TopHeightView extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;
  innerFilter: FilterModel = new FilterModel();
  items: EndpointLogsItemResponse[] = [];
  itemsStat: EndpointStatisticItem[] = []; //статистика методов
  itemTopReqTimeExtends: EndpointStatisticItem = null;

  itemsTopReqTime: GetTopEndpointLogsResponse = null; //топ request
  itemsTopReqTimeExtends: TopEndpointLogItemExtends[] = [];
  topReqTimeExtendsPage: PageResponse = null;

  itemsTopReqVolume: GetTopEndpointLogsResponse = null; //топ request
  itemsTopReqVolumeExtends: TopEndpointLogItemExtends[] = [];
  itemsTopReqVolumeExtendsPage: PageResponse = null;

  itemsTopResp: GetTopEndpointLogsResponse = null; //топ response
  itemsTopRespExtends: TopEndpointLogItemExtends[] = [];
  itemsTopRespExtendsPage: PageResponse = null;

  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }
  error3 = {
    isSuccess: true,
    errorMessage: ""
  }
  error4 = {
    isSuccess: true,
    errorMessage: ""
  }

  endpointsIdArr: number[] = [];
  size = 10;
  // page: PageRequest = { Take: 10, Skip: 0 };
  count = 0;
  skip = 0;

  noPagination = false;
  isLoading = false;

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  onFilterChange() {
    if (this.$refs.pagination as any) {
      (this.$refs.pagination as any).reset();
    }
    this.onclickFilterChange();
  }

  async onclickFilterChange() {
    await this.getItemsTopReqTime(1);
    await this.getItemsTopReqValue(1);
    await this.getItemsTopResp(1);
    this.skip = 0
    if (this.endpointsIdArr.length > 0) {
      try {
        let res = await this.getItemsStat();
        console.log("ress=", res);
      } catch (error) {
        console.log("error=", error);
        return;
      }
    }
  }

  trim(value: string) {
    let rez = value && value.trim() === "" ? null : value;
    return rez;
  }

  mounted() {

  }

  addEnpoints(arr: TopEndpointLogItem[]) {
    arr.forEach((el) => {
      this.endpointsIdArr.push(el.EndpointId);
    });
  }

  async getItemsStat() {
    let unique = this.endpointsIdArr.filter((v, i, a) => a.indexOf(v) === i); // (item, index, arr)
    let response: GetEndpointStatisticsResponse = null;

    this.isLoading = true;

    const innerFilter1 = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.trim(this.filter.Ip),
      UserId: this.trim(this.filter.UserId),
      SessionToken: this.trim(this.filter.SessionToken),

      ConnectionId: this.trim(this.filter.ConnectionId),
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: this.skip, Take: 10},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response =
          await this.$api.TopEndpointLogsService.getCurrentEndpointsStatisticsAsync(
              {
                GetTopEndpointLogsRequest: tmpFilter,
                ForEndpointsIds: unique,
              }
          );
    } catch (error) {
      this.error4.isSuccess = false;
      this.error4.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsStat = response.Items; // GetEndpointStatisticsResponse.Items
    this.isLoading = false;
    this.count = response.Page.Count;

    this.itemsStat.forEach((item) => {
      this.itemsTopReqTimeExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });

      this.itemsTopReqVolumeExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });

      this.itemsTopRespExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });
    });

    return Promise.resolve("good");
  }

  onChangePageTopReqTime(page: number) {
    this.getItemsTopReqTime(page);
    console.log("", page);
  }

  async getItemsTopReqTime(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)

    try {
      response = await this.$api.TopEndpointLogsService.getTopLongRequestsAsync(
          tmpFilter
      );
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopReqTime = response;
    this.itemsTopReqTimeExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.topReqTimeExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  onChangePageTopReqValue(page: number) {
    this.getItemsTopReqValue(page);
    console.log("getItemsTopReqValue", page);
  }

  async getItemsTopReqValue(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response = await this.$api.TopEndpointLogsService.getTopFatRequestsAsync(
          tmpFilter
      );
    } catch (error) {
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopReqVolume = response;
    this.itemsTopReqVolumeExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.itemsTopReqVolumeExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  onChangePageTopResp(page: number) {
    this.getItemsTopResp(page);
  }

  async getItemsTopResp(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response = await this.$api.TopEndpointLogsService.getTopFatResponsesAsync(
          tmpFilter
      );
    } catch (error) {
      this.error3.isSuccess = false;
      this.error3.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopResp = response;
    this.itemsTopRespExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.itemsTopRespExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.size;
    if (this.skip === skip) {
      return;
    }
    this.skip = skip;
    this.getItemsStat();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
}
</script>

<style lang="less">
.tirscript-button {
  width: max-content;
  float: right;
}

.logs-view {
  .tirscript3-table-header-item {
    min-height: 60px;
  }

  h2 {
    font-weight: bold;
    padding: 10px 0;
    color: #322757;
  }
}
</style>